/* Sidebar.css */
.sidebar {
  width: 250px;
  height: 100vh; /* 保证侧边栏高度始终填充整个视窗 */
  background-color: #2c3e50; /* 深色背景 */
  padding: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  position: fixed; /* 固定位置，滚动时不移动 */
}

.sidebar h2 {
  color: #ecf0f1;
  margin-bottom: 20px;
  font-size: 1.5rem; /* 更大、更显眼的标题 */
  text-align: center; /* 居中标题 */
  font-weight: bold;
  border-bottom: 1px solid #34495e; /* 添加分割线 */
  padding-bottom: 10px;
}

.sidebar nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-grow: 1; /* 菜单占据多余空间 */
}

.sidebar nav ul li {
  margin: 10px 0;
}

.sidebar nav ul li a {
  display: flex;
  align-items: center;
  padding: 12px 15px;
  text-decoration: none;
  color: #ecf0f1;
  font-size: 16px;
  border-radius: 8px; /* 圆角设计 */
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}

.sidebar nav ul li a:hover {
  background-color: #34495e; /* 鼠标悬停时的背景色 */
  transform: translateX(5px); /* 微微右移的动画 */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); /* 添加阴影 */
}

.sidebar nav ul li a.active {
  background-color: #1abc9c; /* 激活状态的背景色 */
  color: white;
  font-weight: bold; /* 更醒目的文字 */
  box-shadow: 0 3px 10px rgba(26, 188, 156, 0.4); /* 激活状态的阴影 */
}

.sidebar nav ul li a .icon {
  margin-right: 12px; /* 图标与文字间距 */
  font-size: 20px;
}

.sidebar .logout-button {
  margin-top: auto; /* 将注销按钮固定在底部 */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}

.sidebar .logout-button:hover {
  background-color: #c0392b;
  transform: translateY(-2px); /* 悬停时微微上移 */
  box-shadow: 0 3px 10px rgba(231, 76, 60, 0.4); /* 添加阴影 */
}

@media (max-width: 768px) {
  .sidebar {
    width: 200px; /* 在小屏幕上缩小宽度 */
  }

  .sidebar h2 {
    font-size: 1.2rem; /* 小屏幕上的标题更小 */
  }

  .sidebar nav ul li a {
    font-size: 14px; /* 小屏幕上的文字更小 */
    padding: 10px;
  }

  .sidebar nav ul li a .icon {
    font-size: 18px; /* 小屏幕上的图标更小 */
  }
}
