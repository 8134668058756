/* 页面整体样式 */
body {
  font-family: 'Roboto', sans-serif;
  background-color: #f8f9fa;
  color: #333;
  margin: 0;
  padding: 20px;
}

/* 电影管理标题 */
h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #343a40;
  font-weight: 700;
}

/* 添加电影部分 - 优化为居中、紧凑宽度 */
.add-movie-section {
  background-color: #ffffff;
  padding: 25px;
  border-radius: 12px;
  margin: 0 auto 30px auto; /* 居中对齐 */
  max-width: 600px; /* 设置最大宽度，避免全屏过宽 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.add-movie-section h3 {
  margin-bottom: 15px;
  color: #343a40;
  font-weight: 600;
  text-align: center;
}

.add-movie-section input[type="text"],
.add-movie-section textarea,
.add-movie-section select {
  width: 100%;
  padding: 12px;
  margin: 12px 0;
  border: 1px solid #ced4da;
  border-radius: 6px;
  background-color: #ffffff;
  color: #495057;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
}

.add-movie-section button {
  background-color: #007bff;
  color: #ffffff;
  padding: 12px 25px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: block;
  margin: 20px auto 0; /* 居中按钮 */
}

.add-movie-section button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

/* 电影列表标题样式 */
h3 {
  margin-top: 30px;
  margin-bottom: 15px;
  color: #343a40;
  font-weight: 600;
}

/* 电影列表部分 - 卡片样式进行信息紧凑显示 */
.movies-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.movie-item {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.movie-item img {
  max-width: 150px;
  max-height: 220px;
  object-fit: cover;
  margin-bottom: 15px;
  border-radius: 8px;
}

.movie-item .movie-details {
  margin-bottom: 15px;
}

.movie-item .movie-title {
  font-size: 1.1em;
  font-weight: 600;
  color: #343a40;
  margin-bottom: 5px;
}

.movie-item .movie-category {
  font-size: 0.9em;
  color: #6c757d;
}

/* 删除按钮样式 */
.delete-button {
  background-color: #dc3545;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.delete-button:hover {
  background-color: #c82333;
  transform: translateY(-2px);
}

/* 响应式调整 */
@media (max-width: 768px) {
  .movie-item {
    padding: 15px;
  }

  .movie-item img {
    max-width: 100px;
    max-height: 150px;
  }

  .delete-button {
    font-size: 14px;
    padding: 8px 15px;
  }

  .add-movie-section {
    max-width: 90%; /* 在小屏幕上增加灵活性，保持足够的空间 */
  }
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
}
