/* App container */
.app {
  display: flex;
  min-height: 100vh; /* 保持整个页面的高度 */
}

/* Sidebar */
.sidebar {
  width: 240px; /* 确保侧边栏具有固定宽度 */
  background-color: #4a90e2; /* 你的主色调 */
  color: #fff;
  height: 100vh; /* 确保导航栏充满整个高度 */
  position: fixed; /* 固定在页面左边 */
}

/* Main content area */
.main-content {
  flex: 1; /* 占据剩余空间 */
  margin-left: 240px; /* 留出导航栏的空间 */
  padding: 20px; /* 保持内容的内边距 */
  background-color: #f5f5f5; /* 主内容背景色 */
}
