:root {
  --primary-color: #4a90e2;
  --background-color: #f9fafc;
  --text-color: #333;
  --card-bg-color: #ffffff;
  --card-border-color: #e0e0e0;
  --card-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  --card-hover-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  --border-radius: 12px;
  --transition-duration: 0.3s;
}

.home-page {
  padding: 20px;
  background-color: var(--background-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-title {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 10px;
  text-align: center;
}

.home-description {
  font-size: 1.2rem;
  color: var(--text-color);
  margin-bottom: 30px;
  text-align: center;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1200px;
}

.card {
  background-color: var(--card-bg-color);
  border: 1px solid var(--card-border-color);
  border-radius: var(--border-radius);
  box-shadow: var(--card-shadow);
  padding: 20px;
  text-align: center;
  transition: transform var(--transition-duration), box-shadow var(--transition-duration);
  cursor: pointer;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: var(--card-hover-shadow);
}

.card-icon {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: var(--primary-color);
}

.card-title {
  font-size: 1.5rem;
  color: var(--text-color);
  margin-bottom: 10px;
}

.card-description {
  font-size: 1rem;
  color: #555;
}
