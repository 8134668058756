.contact-submissions-container {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
}

h2 {
  margin-bottom: 20px;
}

.error-message {
  color: red;
  font-weight: bold;
}

.submissions-table {
  width: 100%;
  border-collapse: collapse;
}

.submissions-table th,
.submissions-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.submissions-table th {
  background-color: #f4f4f4;
}

/* 未读表单样式 */
.unread {
  font-weight: bold;
  background-color: #f9f9f9;
}

/* 已读表单样式 */
.read {
  color: #aaa; /* 字体颜色变浅 */
}

/* 标记已读按钮 */
.mark-as-read-button {
  background-color: #2ecc71;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  margin-right: 5px;
}

.mark-as-read-button:hover {
  background-color: #27ae60;
}

/* 删除按钮样式 */
.delete-button {
  background-color: #e74c3c;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

.delete-button:hover {
  background-color: #c0392b;
}
