/* FooterManagement.css */
.footer-management {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sub-section-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.input-field {
  width: 100%;
  margin-bottom: 15px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.select-field {
  width: 100%;
}

.ant-select {
  width: 100%;
}

.ant-select-selector {
  display: flex;
  align-items: center;
  border-radius: 4px !important;
  border: 1px solid #ccc;
  padding: 12px !important; /* 增大选择框的内边距，使其更容易选择 */
  font-size: 16px; /* 增大字体以提高可读性 */
  height: 40px; /* 增大选择框的高度 */
}

.ant-select-selection-search {
  display: flex;
  align-items: center;
  width: 100%;
}

.ant-select-selection-search-input {
  height: 100% !important;
  font-size: 16px;
  width: 100% !important; /* 确保搜索输入框填满选择框的宽度 */
  border: none;
  outline: none;
}

.add-btn {
  margin-top: 10px;
  margin-right: 10px;
  background-color: #4CAF50;
  color: #fff;
  border: none;
  transition: background-color 0.3s ease;
}

.add-btn:hover {
  background-color: #45a049;
}

.btn {
  display: inline-block;
  background-color: #008CBA;
  color: #fff;
  border: none;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #007bb5;
}

.social-link-platform {
  font-weight: bold;
  color: #444;
}

.social-link-url {
  color: #777;
  font-size: 13px;
}

.footer-management .ant-list-item {
  padding: 10px;
  border-bottom: 1px solid #e8e8e8;
  align-items: center;
}

.footer-management .ant-list-item-actions {
  margin-left: auto;
}

.footer-management .ant-list-item-actions > li {
  margin-left: 16px;
}

.footer-management .ant-select {
  margin-bottom: 15px;
}

.footer-management .ant-select-dropdown {
  font-size: 16px;
}

.footer-management .ant-select-item-option {
  padding: 10px;
}
