/* BannerManagement.css */
.banner-management {
  margin: 20px auto;
  width: 100%;
  max-width: 800px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.sub-section-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.input-field {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  color: #333;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.input-field:focus {
  border-color: #1890ff;
  box-shadow: 0 0 4px rgba(24, 144, 255, 0.3);
  outline: none;
}

.btn {
  width: 100%;
  padding: 14px 20px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.3s ease;
}

.btn.update-btn {
  background-color: #1890ff;
  color: white;
}

.btn.update-btn:hover {
  background-color: #1671c1;
  transform: translateY(-2px);
}

.btn.delete-btn {
  background-color: #e74c3c;
  color: white;
  margin-top: 10px;
}

.btn.delete-btn:hover {
  background-color: #c0392b;
  transform: translateY(-2px);
}

.banner-preview {
  margin-top: 20px;
  width: 100%;
  max-width: 500px;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.error {
  color: #e74c3c;
  font-size: 16px;
  margin-bottom: 15px;
  text-align: center;
}

.loading {
  font-size: 18px;
  font-weight: bold;
  color: #f39c12;
  text-align: center;
  margin-top: 20px;
}
