.welcome-page {
  text-align: center;
  padding: 50px 20px;
  background-color: #f5f5f5;
}

.loading {
  font-size: 1.5rem;
  color: #4a90e2;
  margin-top: 20%;
}

.welcome-banner {
  margin-bottom: 40px;
}

.welcome-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.stats {
  background-color: #ffffff;
  padding: 20px;
  margin: 20px auto;
  max-width: 800px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.stats ul {
  list-style-type: none;
  padding: 0;
}

.stats li {
  font-size: 1.2rem;
  margin: 10px 0;
}

.quick-links {
  text-align: left;
  margin: 20px auto;
  max-width: 1200px;
}

.cards-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  flex: 1;
  min-width: 280px;
  max-width: 30%;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.card h3 {
  font-size: 1.5rem;
  color: #4a90e2;
  margin-bottom: 15px;
}

.card p {
  margin-bottom: 20px;
}

.button {
  padding: 10px 20px;
  background-color: #4a90e2;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
}

.button:hover {
  background-color: #f39c12;
}

.button:active {
  transform: scale(0.95);
}
