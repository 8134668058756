.user-profile {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.user-profile h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.profile-details p {
    font-size: 1.1rem;
    margin: 10px 0;
    color: #555;
}

.change-password-button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
}

.change-password-button:hover {
    background-color: #0056b3;
}
