/* VideoManagement.css */
.video-management {
  max-width: 900px;
  margin: 30px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

h2 {
  font-size: 28px;
  color: #333;
  margin-bottom: 30px;
  text-align: center;
}

.error {
  color: #e74c3c;
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
}

.add-video-form {
  background-color: #f9f9f9;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.add-video-form h3 {
  font-size: 22px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.add-video-form input {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  color: #333;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.add-video-form input:focus {
  border-color: #1890ff;
  box-shadow: 0 0 4px rgba(24, 144, 255, 0.3);
  outline: none;
}

.add-video-form button {
  padding: 14px 20px;
  background-color: #1890ff;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.add-video-form button:hover {
  background-color: #1671c1;
  transform: translateY(-2px);
}

.video-list {
  list-style-type: none;
  padding: 0;
}

.video-item {
  background-color: #f9f9f9;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
}

.video-item div {
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
}

.video-item strong {
  color: #1890ff;
}

.video-item button {
  padding: 8px 14px;
  margin-right: 10px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.video-item button:first-child {
  background-color: #1890ff;
  color: white;
}

.video-item button:first-child:hover {
  background-color: #1671c1;
  transform: translateY(-2px);
}

.video-item button:last-child {
  background-color: #e74c3c;
  color: white;
}

.video-item button:last-child:hover {
  background-color: #c0392b;
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .video-management {
    padding: 20px;
  }

  .video-item {
    padding: 10px;
  }

  .add-video-form input,
  .add-video-form button {
    padding: 10px;
  }
}
