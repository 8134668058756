/* FAQManagement.css */
.faq-container {
  max-width: 900px;
  margin: 20px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.faq-header {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 30px;
}

.new-faq {
  background-color: #f9f9f9;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.new-faq h3 {
  font-size: 22px;
  color: #333;
  margin-bottom: 15px;
  text-align: center;
}

.new-faq input,
.new-faq textarea {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  color: #333;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.new-faq input:focus,
.new-faq textarea:focus {
  border-color: #1890ff;
  box-shadow: 0 0 4px rgba(24, 144, 255, 0.3);
  outline: none;
}

.new-faq button {
  padding: 14px 18px;
  background-color: #1890ff;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  width: 100%;
}

.new-faq button:hover {
  background-color: #1671c1;
  transform: translateY(-2px);
}

.faq-list {
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
}

.faq-item {
  display: flex;
  flex-direction: column;  /* Stack question and answer vertically */
  justify-content: space-between;
  padding: 15px;
  margin-bottom: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  height: 180px;  /* Fixed height for each FAQ item */
}

.faq-item .faq-question {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.faq-item .faq-answer {
  font-size: 16px;
  color: #555;
  flex-grow: 1;  /* Allows the answer to take up available space */
  overflow: hidden;
  text-overflow: ellipsis;
}

.faq-item button {
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 8px 14px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  align-self: flex-start; /* Align the button to the left */
}

.faq-item button:hover {
  background-color: #c0392b;
  transform: translateY(-2px);
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  padding: 10px 20px;
  background-color: #1890ff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0 5px;
}

.pagination button:disabled {
  background-color: #ccc;
}

.pagination button:hover {
  background-color: #1671c1;
}

.pagination span {
  font-size: 16px;
  color: #333;
  align-self: center;
}
