/* 全局变量定义，方便主题切换 */
:root {
  --primary-color: #4a90e2;
  --secondary-color: #ffffff;
  --background-color: #111;
  --text-color: #eee;
  --heading-color: #f00;
  --font-family: Arial, sans-serif;
  --font-size: 16px;
  --line-height: 1.6;
  --container-width: 1200px;
  --padding: 20px;
}

/* 重置样式 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 100%; /* 1rem = 16px */
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-family);
  font-size: var(--font-size);
  line-height: var(--line-height);
  background-color: var(--background-color);
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* 链接样式 */
a {
  text-decoration: none;
  color: var(--primary-color);
  transition: color 0.3s ease;
}

a:hover {
  color: #ffffff;
}

/* 按钮样式 */
button {
  font-family: var(--font-family);
  font-size: var(--font-size);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

button:hover {
  background-color: #357abd;
  transform: translateY(-2px);
}

button:active {
  transform: translateY(1px);
}

/* 容器 */
.container {
  max-width: var(--container-width);
  margin: 0 auto;
  padding: var(--padding);
}

/* 标题 */
h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 20px;
  color: var(--heading-color);
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

/* 段落 */
p {
  margin-bottom: 15px;
}

/* 列表 */
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

ul li {
  margin-bottom: 10px;
}

/* 响应式支持 */
@media (max-width: 768px) {
  html {
    font-size: 90%; /* 1rem = 14.4px */
  }

  .container {
    padding: 10px;
  }
}
